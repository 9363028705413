import { Component, Input, OnInit } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.page.html',
  styleUrls: ['./modal-alert.page.scss'],
})
export class ModalAlertPage implements OnInit {
  @Input('communication') communication;
  private logo: any = environment.logoSlogan;

  constructor(
    public modalController: ModalController,
    private platform: Platform,
    private iab: InAppBrowser,
  ) { }

  ngOnInit() {
  }

  async Confirm() {
    if (this.communication.majorMsg.btnUrl) {
      if (this.platform.is('android')) {
        const browser = await this.iab.create(
          this.communication.majorMsg.btnUrl,
          '_system',
          'location=yes,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,Navigationbuttoncolor=#3c6070,toolbarcolor=#3c6070'
        );
        browser.show();
        this.modalController.dismiss({});
      } else if (this.platform.is('ios')) {
        const browser = await this.iab.create(
          this.communication.majorMsg.btnUrl,
          '_system',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
        this.modalController.dismiss({});
      } else {
        if (!window.ActiveXObject) {
          const save = document.createElement('a');
          save.href = this.communication.majorMsg.btnUrl;
          save.target = '_system';
          save.download = 'IR' || 'unknown';

          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);

          (window.URL || window.webkitURL).revokeObjectURL(save.href);
          this.modalController.dismiss({});
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
          const wwindow = window.open(this.communication.majorMsg.btnUrl, '_system');
          wwindow.document.close();
          wwindow.document.execCommand('SaveAs', true, 'IR' || this.communication.majorMsg.btnUrl);
          wwindow.close();
          this.modalController.dismiss({});
        }
      }
    }
    else {
      this.modalController.dismiss({});
    }
  }

}
