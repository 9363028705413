/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-notification',
  templateUrl: './modal-notification.page.html',
  styleUrls: ['./modal-notification.page.scss'],
})
export class ModalNotificationPage {
  @Input('notification') notification;

  constructor(public modalController: ModalController,) {}

  ionViewWillEnter() {
    // console.log(this.notification);
  }

  formatStatus(value) {
    if (value === 'Aguardando Liquidação') {
      return 'Aguardando Liquidação';
    }
    if (value === 'Vencida') {
      return 'Vencido';
    }
  }

  formatVencimento(val) {
    return val.replace('00:00:00', '');
  }

  openBoleto(ev) {
    this.modalController.dismiss({
      boleto: ev
    });
  }
}
