/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  storage: localStorage,
  AUTH_SERVER_ADDRESS: 'https://administradora.easyplan.com.br',
  expires: 3600000,
  logo: 'assets/logo/Logo_EasyPlan.png',
  logoSlogan: 'assets/logo/Logo_EasyPlan_Slogan.png',
  apiBaseEasyPlanCorretor: 'https://apis.easyplan.com.br:3088',
  apiBaseEasyPlan: 'https://apis.easyplan.com.br:3090',
  KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhY2Vzc28gYSBjcmVhdGUgdG9rZW4iLCJuYW1lIjoiRWFzeVBsYW4gRGVzZW52b2x2aW1lbnRvIiwiaWF0IjozMTUzNjAwMH0.ozoq_g4oM0_ERwumI6VpSqClJVI6J5PvPtORkqkwyT8",

  firebaseConfig: {
    apiKey: 'AIzaSyCFqUFv5RP6aWUjz2-tuPQXQ1DoCpKCqcQ',
    authDomain: 'easyplan-clientes.firebaseapp.com',
    projectId: 'easyplan-clientes',
    storageBucket: 'easyplan-clientes.appspot.com',
    messagingSenderId: '713716540104',
    appId: '1:713716540104:web:07d2f895de0bfe28dd7bfd',
  },

  recaptcha: {
    siteKey: '6LephysmAAAAACQCEPIvMuO4Orewy5_sE-JkGvdY',
  },

  userMock: {
    modelo: {
      adesaoID: '3978cdd2-6713-11eb-aad8-020506faab1c',
      auditoria: false,
      arquivos: [
        {
          aadesaoID: '61c0db13-773c-11e9-a97c-02c7d817cbe1',
          arquivoID: 'a38efe9c-773c-11e9-a97c-02c7d817cbe1',
          linkCompleto:
            'https://administradora.easyplan.com.br/AreaBeneficiario/VisualizarArquivo?id=a38efe9c-773c-11e9-a97c-02c7d817cbe1',
          nome: 'MIGUEL AZEVEDO ANDRADE DOS SANTOS ',
          nomeArquivo: '0067 - MIGUEL AZEVEDO ANDRADE DOS SANTOS.pdf',
          tipoImagem: 'Comprovante Residência',
          tipoImagemID: 'd8132031-7d98-4e4b-b1f8-b897d45c052c',
        },
      ],
      contratoCobrancaID: 'c13d305a-dd8f-11ea-9c12-4439c44efa43',
      tipoCobranca: 'Boleto',
      contratoConvenioBancarioID: 'c13d305b-dd8f-11ea-9c12-4439c44efa43',
      convenio: 'Banco Bradesco S.A. | Boleto',
      contratoProdutoID: 'f7c15d1e-dd8e-11ea-9c12-4439c44efa43',
      produto: 'OPUS DF APARTAMENTO C/ COPART',
      operadoraLinha: 'DISTRITO FEDERAL /  GOIÁS',
      operadora: 'SMILE',
      contrato: 'ANPLIA Smile',
      empresa: 'Anplia',
      cnpj: '24406622000182',
      corretoraID: '34d9070a-5a1d-11e9-a97b-02c7d817cbe1',
      corretora: 'EEM CORRETORA MATRIZ',
      dataAssinatura: '02/02/2021 00:00:00',
      dataVigencia: '16/02/2021 00:00:00',
      diaCobranca: 16,
      equipeVendasID: '683268cc-5a1d-11e9-a97b-02c7d817cbe1',
      equipeVendas: 'VENDAS EEM MATRIZ',
      migrada: false,
      numeroProposta: '0000',
      pericia: false,
      posPago: false,
      mesVencimento: 0,
      produtorID: '67e7cd09-99d0-11e9-a97c-02c7d817cbe1',
      produtor: 'MARIA DE LOURDES DE SOUSA CUNHA',
      resposanvelID: null,
      grupoRegionalID: 'ffa8c0f5-2316-4601-bea8-1e429121329d',
      grupoRegional: 'Distrito Federal',
      responsavel: '',
      situacaoID: 'df8804bf-bbca-4942-92e7-c8cffab000a2',
      situacao: 'Suspensa',
      odontologico: false,
      odontologicoAux: 'Saúde',
      modalidadeVendaID: '4423e970-1b3b-11e8-9c1f-5cc9d355fe15',
      operadoraVidasID: '4510f354-5edc-11ea-9c05-4439c44efa43',
      empresaID: 'b7ce6208-dd8d-11ea-9c12-4439c44efa43',
      contratoID: '5a30be4c-dd8e-11ea-9c12-4439c44efa43',
      operadoraLinhaID: '1f580a57-5edc-11ea-9c05-4439c44efa43',
      produtoID: '3f90aec8-5fb2-11ea-9c05-4439c44efa43',
      operadoraID: 'e15778ff-5edb-11ea-9c05-4439c44efa43',
      grupoCorretoraID: '47e2491b-5a1f-11e9-a97b-02c7d817cbe1',
      grupoCorretora: 'VENDAS PASTINHAS',
      comissaoID: '7e8f9d59-5fb8-11ea-9c05-4439c44efa43',
      comissao: 'Comissão Matriz Smile 30% ',
      observacao: null,
      cancelada: false,
      dataInativacao: null,
      dataCancelamento: null,
      motivoCancelamento: '',
      descricaoCancelamento: '',
      titular: {
        ContentEncoding: null,
        ContentType: null,
        Data: {
          adesaoID: '3978cdd2-6713-11eb-aad8-020506faab1c',
          nome: 'MAYARA AZEVEDO ANDRADE DOS SANTOS',
          tipoObjetoID: 'f1c539b9-a768-4701-83d8-dc28bd58182d',
          altura: null,
          cns: '706008336077042',
          cpf: '00283565195',
          dataExpedicao: '26/11/1982 00:00:00',
          dataNascimento: '26/11/1982 00:00:00',
          filiacao: 'MARIA CLAUDIA DA SILVA CABRAL DE VASCONCELLOS',
          masculino: false,
          sexo: 'Femenino',
          matricula: '0001507',
          nascidoVivo: null,
          orgaoExpedidor: 'SSPDF',
          peso: null,
          pisPasep: null,
          profissaoID: null,
          profissao: '',
          tipoDocumentoPessoalID: '9deb05d3-1b3c-11e8-9c1f-5cc9d355fe15',
          documento: '2081581',
          situacaoFuncionalID: null,
          situacaoFuncional: '',
          tipoEstadoCivilID: '17b31126-5f94-4b56-947b-df37ae0f0a53',
          tipoEstadoCivil: 'Outro(s)',
        },
        JsonRequestBehavior: 1,
        MaxJsonLength: null,
        RecursionLimit: null,
      },
      adesaoVinculadas: [],
      beneficiarios: [
        {
          id: '7c131e17-6714-11eb-aad8-020506faab1c',
          nome: 'LAIS HELENA CABRAL DE VASCONCELLOS DALDEGAN',
          cpf: '09213782110',
          dataVigencia: '16/02/2021 00:00:00',
          dataNascimento: '15/10/2006 00:00:00',
          responsavelFinanceiro: false,
          adesaoBeneficiarioID: '7c131e17-6714-11eb-aad8-020506faab1c',
          matricula: '0001506',
          nomeComDependencia:
            'LAIS HELENA CABRAL DE VASCONCELLOS DALDEGAN - (Outro)',
          proximaFaixaEtaria: '15/10/2025 00:00:00',
          ultimaFaixaEtaria: null,
          tipoDependencia: 'Outro',
          codigoOperadora: null,
          responsavelFinanceiroAux: 'Não',
          cancelado: false,
          dataInativacao: null,
          motivoCancelamento: '',
          dataCancelamento: null,
          isentoCobrancaAux: 'Não',
          suspenso: true,
          valor: 254.97,
        },
        {
          id: '55440932-6714-11eb-aad8-020506faab1c',
          nome: 'HELENA CABRAL DE VASCONCELLOS BRAGA',
          cpf: '00283565195',
          dataVigencia: '16/02/2021 00:00:00',
          dataNascimento: '26/11/1982 00:00:00',
          responsavelFinanceiro: false,
          adesaoBeneficiarioID: '55440932-6714-11eb-aad8-020506faab1c',
          matricula: '0001507',
          nomeComDependencia: 'HELENA CABRAL DE VASCONCELLOS BRAGA - (Titular)',
          proximaFaixaEtaria: '26/11/2021 00:00:00',
          ultimaFaixaEtaria: '26/11/2016 00:00:00',
          tipoDependencia: 'Titular',
          codigoOperadora: null,
          responsavelFinanceiroAux: 'Não',
          cancelado: false,
          dataInativacao: null,
          motivoCancelamento: '',
          dataCancelamento: null,
          isentoCobrancaAux: 'Não',
          suspenso: true,
          valor: 460.14,
        },
        {
          id: '72a3ef3d-6714-11eb-aad8-020506faab1c',
          nome: 'JOAO MATHEUS CABRAL DE VASCONCELLOS DALDEGAN',
          cpf: '04974386239',
          dataVigencia: '16/02/2021 00:00:00',
          dataNascimento: '23/05/2000 00:00:00',
          responsavelFinanceiro: false,
          adesaoBeneficiarioID: '72a3ef3d-6714-11eb-aad8-020506faab1c',
          matricula: '0001508',
          nomeComDependencia:
            'JOAO MATHEUS CABRAL DE VASCONCELLOS DALDEGAN - (Outro)',
          proximaFaixaEtaria: '23/05/2024 00:00:00',
          ultimaFaixaEtaria: '23/05/2019 00:00:00',
          tipoDependencia: 'Outro',
          codigoOperadora: null,
          responsavelFinanceiroAux: 'Não',
          cancelado: false,
          dataInativacao: null,
          motivoCancelamento: '',
          dataCancelamento: null,
          isentoCobrancaAux: 'Não',
          suspenso: true,
          valor: 318.72,
        },
        {
          id: '677def5d-6714-11eb-aad8-020506faab1c',
          nome: 'MARIA CLAUDIA DA SILVA CABRAL DE VASCONCELLOS',
          cpf: '81089848749',
          dataVigencia: '16/02/2021 00:00:00',
          dataNascimento: '10/04/1964 00:00:00',
          responsavelFinanceiro: true,
          adesaoBeneficiarioID: '677def5d-6714-11eb-aad8-020506faab1c',
          matricula: null,
          nomeComDependencia:
            'MARIA CLAUDIA DA SILVA CABRAL DE VASCONCELLOS - (Pai/Mãe)',
          proximaFaixaEtaria: '10/04/2023 00:00:00',
          ultimaFaixaEtaria: '10/04/2018 00:00:00',
          tipoDependencia: 'Pai/Mãe',
          codigoOperadora: null,
          responsavelFinanceiroAux: 'Sim',
          cancelado: false,
          dataInativacao: null,
          motivoCancelamento: '',
          dataCancelamento: null,
          isentoCobrancaAux: 'Sim',
          suspenso: true,
          valor: 0.0,
        },
        {
          id: '89e07abc-6714-11eb-aad8-020506faab1c',
          nome: 'MARIA BEATRIZ CABRAL DE VASCONCELLOS DALDEGAN',
          cpf: '09213743130',
          dataVigencia: '16/02/2021 00:00:00',
          dataNascimento: '04/05/2009 00:00:00',
          responsavelFinanceiro: false,
          adesaoBeneficiarioID: '89e07abc-6714-11eb-aad8-020506faab1c',
          matricula: '0001509',
          nomeComDependencia:
            'MARIA BEATRIZ CABRAL DE VASCONCELLOS DALDEGAN - (Outro)',
          proximaFaixaEtaria: '04/05/2028 00:00:00',
          ultimaFaixaEtaria: null,
          tipoDependencia: 'Outro',
          codigoOperadora: null,
          responsavelFinanceiroAux: 'Não',
          cancelado: false,
          dataInativacao: null,
          motivoCancelamento: '',
          dataCancelamento: null,
          isentoCobrancaAux: 'Não',
          suspenso: true,
          valor: 254.97,
        },
      ],
      parcelas: [
        {
          parcelaID: 40502,
          adesaoID: '3978cdd2-6713-11eb-aad8-020506faab1c',
          contratoID: '5a30be4c-dd8e-11ea-9c12-4439c44efa43',
          anoReferencia: 2021,
          tipoObjetoID: '326b143c-e05d-4c34-bba4-b8db7f677803',
          dataPagamento: '02/03/2021 00:00:00',
          dataPrimeiraCobranca: '16/02/2021 00:00:00',
          dataVencimento: '16/02/2021 00:00:00',
          dataVencimentoOriginal: '16/02/2021 00:00:00',
          mesReferencia: 2,
          parcela: 1,
          situacaoID: '22c969cb-affb-41ec-ab9a-85e4f2d26017',
          situacao: 'Paga',
          tipoCobrancaID: '410b8412-f2b3-4514-9539-ed863c5102bd',
          tipoCobranca: 'Boleto',
          tipoPagamentoID: '83238d43-f18a-4034-a658-3f5c2b48a018',
          tipoPagamento: 'Boleto',
          valorPagamento: 1320.59,
          valorParcelaOriginal: 1288.8,
          valor: 1288.8,
          referencia: '2/2021',
          situacaoAdesao: 'df8804bf-bbca-4942-92e7-c8cffab000a2',
          quantidadeParcelas: 6,
          diasAtraso: 0,
          responsavel: 'MARIA CLAUDIA DA SILVA CABRAL DE VASCONCELLOS',
          titular: 'HELENA CABRAL DE VASCONCELLOS BRAGA',
          documentoResponsavel: '81089848749',
          documentoTitular: '00283565195',
          documentoEmpresa: '24406622000182',
          emAberto: false,
          boletoID: 'bee41571-6b0a-11eb-aada-020506faab1c',
          linkBoleto: '/Financeiro/VisualizarBoletoPDF?id=',
          linkCompletoBoleto:
            'https://administradora.easyplan.com.br/Financeiro/VisualizarBoletoPDF?id=bee41571-6b0a-11eb-aada-020506faab1c',
        },
        {
          parcelaID: 40503,
          adesaoID: '3978cdd2-6713-11eb-aad8-020506faab1c',
          contratoID: '5a30be4c-dd8e-11ea-9c12-4439c44efa43',
          anoReferencia: 2021,
          tipoObjetoID: '326b143c-e05d-4c34-bba4-b8db7f677803',
          dataPagamento: '31/03/2021 00:00:00',
          dataPrimeiraCobranca: '16/03/2021 00:00:00',
          dataVencimento: '16/03/2021 00:00:00',
          dataVencimentoOriginal: '16/03/2021 00:00:00',
          mesReferencia: 3,
          parcela: 2,
          situacaoID: '22c969cb-affb-41ec-ab9a-85e4f2d26017',
          situacao: 'Vencida',
          tipoCobrancaID: '410b8412-f2b3-4514-9539-ed863c5102bd',
          tipoCobranca: 'Boleto',
          tipoPagamentoID: '83238d43-f18a-4034-a658-3f5c2b48a018',
          tipoPagamento: 'Boleto',
          valorPagamento: 1321.02,
          valorParcelaOriginal: 1288.8,
          valor: 1288.8,
          referencia: '3/2021',
          situacaoAdesao: 'df8804bf-bbca-4942-92e7-c8cffab000a2',
          quantidadeParcelas: 6,
          diasAtraso: 0,
          responsavel: 'MARIA CLAUDIA DA SILVA CABRAL DE VASCONCELLOS',
          titular: 'HELENA CABRAL DE VASCONCELLOS BRAGA',
          documentoResponsavel: '81089848749',
          documentoTitular: '00283565195',
          documentoEmpresa: '24406622000182',
          emAberto: false,
          boletoID: '56a6cf08-7c55-11eb-aae3-020506faab1c',
          linkBoleto: '/Financeiro/VisualizarBoletoPDF?id=',
          linkCompletoBoleto:
            'https://administradora.easyplan.com.br/Financeiro/VisualizarBoletoPDF?id=56a6cf08-7c55-11eb-aae3-020506faab1c',
        },
        {
          parcelaID: 40504,
          adesaoID: '3978cdd2-6713-11eb-aad8-020506faab1c',
          contratoID: '5a30be4c-dd8e-11ea-9c12-4439c44efa43',
          anoReferencia: 2021,
          tipoObjetoID: '326b143c-e05d-4c34-bba4-b8db7f677803',
          dataPagamento: null,
          dataPrimeiraCobranca: '16/04/2021 00:00:00',
          dataVencimento: '16/04/2021 00:00:00',
          dataVencimentoOriginal: '16/04/2021 00:00:00',
          mesReferencia: 4,
          parcela: 3,
          situacaoID: 'cd4c174f-3459-4724-86ed-f2be8f750dbb',
          situacao: 'Aguardando Liquidação',
          tipoCobrancaID: '410b8412-f2b3-4514-9539-ed863c5102bd',
          tipoCobranca: 'Boleto',
          tipoPagamentoID: null,
          tipoPagamento: '',
          valorPagamento: null,
          valorParcelaOriginal: 1288.8,
          valor: 1288.8,
          referencia: '4/2021',
          situacaoAdesao: 'df8804bf-bbca-4942-92e7-c8cffab000a2',
          quantidadeParcelas: 6,
          diasAtraso: 22,
          responsavel: 'MARIA CLAUDIA DA SILVA CABRAL DE VASCONCELLOS',
          titular: 'HELENA CABRAL DE VASCONCELLOS BRAGA',
          documentoResponsavel: '81089848749',
          documentoTitular: '00283565195',
          documentoEmpresa: '24406622000182',
          emAberto: true,
          boletoID: '8e5ea6c2-9329-11eb-aae4-020506faab1c',
          linkBoleto: '/Financeiro/VisualizarBoletoPDF?id=',
          linkCompletoBoleto:
            'https://administradora.easyplan.com.br/Financeiro/VisualizarBoletoPDF?id=8e5ea6c2-9329-11eb-aae4-020506faab1c',
        },
      ],
      linkCobranca:
        'https://banco.bradesco/html/classic/produtos-servicos/mais-produtos-servicos/segunda-via-boleto.shtm',
      formaCobranca: 'boleto',
      boletos: [
        {
          emAberto: false,
          linkBoleto:
            '/Financeiro/VisualizarBoletoPDF?id=bee41571-6b0a-11eb-aada-020506faab1c',
          linkCompletoBoleto:
            'https://administradora.easyplan.com.br/Financeiro/VisualizarBoletoPDF?id=bee41571-6b0a-11eb-aada-020506faab1c',
          registrado: true,
          nossoNumero: '00000012628',
          numeroBoleto: '00000012628',
          dataVencimento: '16/02/2021 00:00:00',
          dataPagamento: '02/03/2021 00:00:00',
          valorBoleto: 1288.8,
          valorPagamento: 1320.59,
          tipo: 'Faturamento',
          parcelaInicial: 1,
          parcelaFinal: 1,
          diasAtraso: 81,
          referencia: '2/2021',
        },
        {
          emAberto: false,
          linkBoleto:
            '/Financeiro/VisualizarBoletoPDF?id=56a6cf08-7c55-11eb-aae3-020506faab1c',
          linkCompletoBoleto:
            'https://administradora.easyplan.com.br/Financeiro/VisualizarBoletoPDF?id=56a6cf08-7c55-11eb-aae3-020506faab1c',
          registrado: true,
          nossoNumero: '00000014247',
          numeroBoleto: '00000014247',
          dataVencimento: '16/03/2021 00:00:00',
          dataPagamento: '31/03/2021 00:00:00',
          valorBoleto: 1288.8,
          valorPagamento: 1321.02,
          tipo: 'Faturamento',
          parcelaInicial: 1,
          parcelaFinal: 1,
          diasAtraso: 53,
          referencia: '3/2021',
        },
        {
          emAberto: true,
          linkBoleto:
            '/Financeiro/VisualizarBoletoPDF?id=8e5ea6c2-9329-11eb-aae4-020506faab1c',
          linkCompletoBoleto:
            'https://administradora.easyplan.com.br/Financeiro/VisualizarBoletoPDF?id=8e5ea6c2-9329-11eb-aae4-020506faab1c',
          registrado: true,
          nossoNumero: '00000016097',
          numeroBoleto: '00000016097',
          dataVencimento: '16/04/2021 00:00:00',
          dataPagamento: null,
          valorBoleto: 1288.8,
          valorPagamento: null,
          tipo: 'Faturamento',
          parcelaInicial: 1,
          parcelaFinal: 1,
          diasAtraso: 22,
          referencia: '4/2021',
        },
      ],
      objetoProduto: {
        nome: 'OPUS DF APARTAMENTO C/ COPART',
        abrangencia: 'Regional',
        acomodacao: 'Apartamento ',
        segmentacaoAssistencial: 'Ambulatorial + Hospitalar com Obstetrícia',
        tipoCoparticipacao: 'Com Coparticipação',
      },
      valorTotal: 1288.8,
    },
    urlIR: [
      {
        url: 'https://drive.google.com/file/d/19c3chiilwBYm78FBau_Jyo5afhGi8ZAl/view?usp=sharing',
        ano: 2019,
      },
      {
        url: 'https://americalatina.dint.fgv.br/sites/americalatina.dint.fgv.br/files/teste33.pdf',
        ano: 2020,
      },
    ],
    permissoes: {
      'tipoprodutor.visualizar': false,
      'adesao.inserir': false,
      'alteracaodiavencimentocontrato.visualizar': false,
      'produtocomplementar.visualizar': false,
      'tipodocumentopessoal.gerencia': false,
      'gruporegional.visualizar': false,
      'beneficiario.visualizar': true,
      'corretora.editar': false,
      'grupoContrato.excluir': false,
      'cancelamento.visualizar': false,
      'movimentacao.excluir': false,
      'relatoriofinanceiroinadinplencia.porempresa': false,
      'naturezaatendimento.gerencia': false,
      'acessoLog.visualizar': false,
      'relatoriocomissionamentoprolabore.visualizar': false,
      'produto.excluir': false,
      'abrangencia.gerencia': false,
      'comissionamento.visualizar': false,
      'exportacaosmiles.visualizar': false,
      'comissao.inserir': false,
      'financeiro.editar': false,
      'proposta.inserir': false,
      'reativacaobeneficiario.excluir': false,
      'produtocomplementartipo.gerencia': false,
      'proposta.visualizar': false,
      'suspensao.excluir': false,
      'comissionamento.editar': false,
      'tipodocumentopessoal.visualizar': false,
      'reativacaocontrato.excluir': false,
      'parcelacobranca.editar': false,
      'relatorioatendimentototal.visualizar': false,
      'alteracaocomissaoadesao.visualizar': false,
      'empresa.excluir': false,
      'produtor.editar': false,
      'comissionamento.inserir': false,
      'relatoriofinanceiroenviosms.visualizar': false,
      'alteracaoformacobrancaadesao.inserir': false,
      'exportacaoidealsaude.visualizar': false,
      'alteracaoproduto.excluir': false,
      'acomodacao.visualizar': false,
      'auditoria.visualizar': false,
      'tipoMovimentacao.visualizar': false,
      'relatoriocomissionamentobonificacao.visualizar': false,
      'equipevendas.inserir': false,
      'usuario.visualizar': false,
      'financeiro.visualizar': false,
      'financeiro.inserir': false,
      'tabela.excluir': false,
      'equipevendas.excluir': false,
      'produtocomplementar.editar': false,
      'naturezazatendimento.visualizar': false,
      'auditoria.gerencia': false,
      'ajusteapolice.visualizar': false,
      'reativacaobeneficiario.visualizar': false,
      'alteracaocomissaocontrato.editar': false,
      'alteracaocomissaocontrato.visualizar': false,
      'produto.visualizar': false,
      'relatoriocomercialcorretores.visualizar': false,
      'suspensao.inserir': false,
      'beneficiario.excluir': true,
      'tipoprodutor.gerencia': false,
      'beneficiario.editar': true,
      'proposta.editar': false,
      'corretora.inserir': false,
      'parcelacobranca.excluir': false,
      'comissionamento.excluir': false,
      'expedicao.visualizar': false,
      'comissao.editar': false,
      'tipoatendimento.gerencia': false,
      'produtor.visualizar': false,
      'grupoContrato.editar': false,
      'relatoriocadastrocontratopordataativacao.visualizar': false,
      'atendimento.visualizar': true,
      'empresa.inserir': false,
      'corretora.visualizar': false,
      'banco.gerencia': false,
      'suspensao.editar': false,
      'alteracaodiavencimentoadesao.visualizar': false,
      'produto.inserir': false,
      'alteracaocontrato.excluir': false,
      'produtocomplementartipo.visualizar': false,
      'geral.areaDeTrabalho': false,
      'carencia.visualizar': false,
      'estipulante.editar': false,
      'atendimentoprodutor.visualizar': false,
      'relatoriocomercialcorretoras.visualizar': false,
      'estipulante.inserir': false,
      'coparticipacaoprocedimento.excluir': false,
      'financeiroMovimentacao.excluir': false,
      'cancelamento.editar': false,
      'reativacaocontrato.inserir': false,
      'alteracaodiavencimentoadesao.editar': false,
      'relatoriocadastroreajustes.visualizar': false,
      'comissao.excluir': false,
      'sistemaParametro.excluir': false,
      'proposta.excluir': false,
      'segmentacaoassistencial.visualizar': false,
      'estipulante.visualizar': false,
      'contratante.excluir': false,
      'indicador.financeiro': false,
      'atendimentoempresarial.visualizar': false,
      'alteracaoformacobrancaadesao.editar': false,
      'atendimentoprodutor.editar': false,
      'corretora.excluir': false,
      'inclusaodependente.visualizar': false,
      'dmed.inserir': false,
      'operadora.inserir': false,
      'alteracaodiavencimentocontrato.editar': false,
      'mensagemboleto.gerencia': false,
      'acessoPerfil.excluir': false,
      'tipoMovimentacao.inserir': false,
      'checklist.gerencia': false,
      'alteracaocomissaoadesao.editar': false,
      'atendimentoprodutor.inserir': false,
      'contrato.excluir': false,
      'inclusaodependente.editar': false,
      'grupocorretora.editar': false,
      'alteracaodiavencimentocontrato.excluir': false,
      'alteracaoformacobrancaadesao.excluir': false,
      'reativacaocontrato.visualizar': false,
      'movimentacao.visualizar': false,
      'alteracaocomissaocontrato.inserir': false,
      'contratante.inserir': false,
      'relatoriocadastrocarteiradatavigencia.visualizar': false,
      'parcelacobranca.visualizar': false,
      'geral.generico': false,
      'reativacaobeneficiario.inserir': false,
      'relatoriocomercialvendaspordatarecepcao.visualizar': false,
      'relatoriocomercialvendaspordatavigencia.visualizar': false,
      'contrato.inserir': false,
      'relatoriocadastrocontratovidas.visualizar': false,
      'carenciaprazo.gerencia': false,
      'operadora.excluir': false,
      'grupocorretora.inserir': false,
      'atendimento.excluir': false,
      'carencia.inserir': false,
      'relatoriocomissionamentoextratocorr.visualizar': false,
      'adesao.visualizar': false,
      'financeiroMovimentacao.visualizar': false,
      'expedicao.inserir': false,
      'relatoriocadastrocontratocarteiradatavigencia.visualizar': false,
      'adesao.excluir': false,
      'grupoContrato.inserir': false,
      'beneficiario.inserir': false,
      'financeiroMovimentacao.inserir': false,
      'movimentacao.inserir': false,
      'bandeiracartao.gerencia': false,
      'estipulante.excluir': false,
      'tipocoparticipacao.visualizar': false,
      'segmentacaoassistencial.gerencia': false,
      'carenciaprazo.visualizar': false,
      'usuario.editar': false,
      'atendimentoprodutor.excluir': false,
      'tipoidentificacaoendereco.visualizar': false,
      'sistemaParametro.inserir': false,
      'coparticipacaoprocedimento.inserir': false,
      'dmed.editar': false,
      'relatoriofinanceirobaixas.visualizar': false,
      'alteracaodiavencimentoadesao.inserir': false,
      'coparticipacaoprocedimento.editar': false,
      'cancelamento.excluir': false,
      'modalidadevenda.visualizar': false,
      'gruporegional.gerencia': false,
      'cancelamento.inserir': false,
      'carencia.excluir': false,
      'equipevendas.editar': false,
      'movimentacao.editar': false,
      'grupocorretora.excluir': false,
      'relatoriofinanceirocontabil.visualizar': false,
      'relatoriocadastrocancelamento.visualizar': false,
      'reajuste.visualizar': false,
      'dmed.visualizar': false,
      'portalcorretor.gerenciar': false,
      'produtor.inserir': false,
      'produtocomplementar.excluir': false,
      'dmed.excluir': false,
      'expedicao.excluir': false,
      'faixaetaria.gerencia': false,
      'tipoatendimento.visualizar': false,
      'parcelacobranca.inserir': false,
      'alteracaocontrato.visualizar': false,
      'ajusteapolice.inserir': false,
      'produto.editar': false,
      'tipoMovimentacao.editar': false,
      'relatoriocomercialvendaspordataassinatura.visualizar': false,
      'ajusteapolice.excluir': false,
      'faixaetaria.visualizar': false,
      'checklist.visualizar': false,
      'empresa.editar': false,
      'atendimentoempresarial.inserir': false,
      'acessoPerfil.editar': false,
      'reajuste.gerencia': false,
      'relatoriofinanceiroparcelascompleto.visualizar': false,
      'alteracaoproduto.visualizar': false,
      'alteracaocomissaoadesao.excluir': false,
      'tipocoparticipacao.gerencia': false,
      'setor.visualizar': false,
      'exportacaosaudesimsenior.visualizar': false,
      'abrangencia.visualizar': false,
      'inclusaodependente.inserir': false,
      'tipoidentificacaoendereco.gerencia': false,
      'atendimentoempresarial.editar': false,
      'carencia.editar': false,
      'banco.visualizar': false,
      'suspensao.visualizar': false,
      'modalidadevenda.gerencia': false,
      'tipoMovimentacao.excluir': false,
      'contratante.visualizar': false,
      'contrato.editar': false,
      'grupocorretora.visualizar': false,
      'reativacaocontrato.editar': false,
      'produtocomplementar.inserir': false,
      'usuario.excluir': false,
      'alteracaocomissaocontrato.excluir': false,
      'comissao.visualizar': false,
      'adesao.editar': false,
      'reativacaobeneficiario.editar': false,
      'tabela.inserir': false,
      'acessoPerfil.visualizar': false,
      'sistemaParametro.visualizar': false,
      'contrato.visualizar': false,
      'inclusaodependente.excluir': false,
      'alteracaodiavencimentoadesao.excluir': false,
      'coparticipacaoprocedimento.visualizar': false,
      'ajusteapolice.editar': false,
      'bandeiracartao.visualizar': false,
      'atendimento.inserir': true,
      'alteracaoproduto.editar': false,
      'relatoriofinanceirocontratovalorreferencia.visualizar': false,
      'setor.gerencia': false,
      'contratante.editar': false,
      'usuario.inserir': false,
      'equipevendas.visualizar': false,
      'alteracaocontrato.editar': false,
      'alteracaodiavencimentocontrato.inserir': false,
      'alteracaocomissaoadesao.inserir': false,
      'financeiro.excluir': false,
      'tabela.visualizar': false,
      'tabela.editar': false,
      'atendimentoempresarial.excluir': false,
      'operadora.visualizar': false,
      'expedicao.editar': false,
      'atendimento.editar': false,
      'acomodacao.gerencia': false,
      'relatorioempresacontatos.visualizar': false,
      'relatoriocadastrotabelaativas.visualizar': false,
      'alteracaoformacobrancaadesao.visualizar': false,
      'alteracaocontrato.inserir': false,
      'operadora.editar': false,
      'empresa.visualizar': false,
      'relatoriocadastrorespostads.visualizar': false,
      'financeiroMovimentacao.editar': false,
      'alteracaoproduto.inserir': false,
      'grupoContrato.visualizar': false,
      'relatoriocomissionamentoprovisionamento.visualizar': false,
      'sistemaParametro.editar': false,
      'exportacaoplansaude.visualizar': false,
      'mensagemboleto.visualizar': false,
      'acessoPerfil.inserir': false,
      'produtor.excluir': false,
    },
    sucesso: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
